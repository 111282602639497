<template>
  <div class="search-page">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <div class="search-container">
      <div class="search-catalog">
        <search-catalog/>
      </div>
      <div class="search-content">
        <b-spinner v-if="loading"/>

        <search-content v-else/>
      </div>
    </div>
  </div>
</template>

<script>
  import { amsClient } from 'src/service/ams';
  import { mapActions, mapGetters } from 'vuex';

  import SearchCatalog from './SearchCatalog/SearchCatalog';
  import SearchContent from './SearchContent/SearchContent';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';

  export default {
    name: 'Search',
    components: {
      SearchCatalog,
      SearchContent,
    },
    data() {
      return {
        initialState: null,
        metadata: {},
      }
    },
    computed: {
      ...mapGetters({
        loading: 'searchPage/loading',
        head: 'searchPage/head',
      }),
    },
    watch: {
      head: function(head) {
        const metadata = normaliseMetaTags(head);
        metadata.title = 'Search results';
        this.metadata = metadata;
      },
    },
    beforeRouteEnter(to, from, next) {
      if(to.query?.providerId) {
        const link = `/search/?term=${to.query?.term}&provider_id=${to.query?.providerId}`;

        next(vm => {
          vm.toggleLoading(true);

          vm.fetchStores(link)
            .then(data => {
              vm.setSearchDataAction(data);
              vm.checkInitState(data);
              vm.toggleLoading(false);
            });
        });

        return;
      };

      next(vm => {
        vm.toggleLoading(true);
        vm.getData(to.fullPath, to.query?.initialState, to.query?.term);
      });
    },
    beforeRouteUpdate(to, from, next) {
      this.toggleLoading(true);
      this.getData(to.fullPath, to.query?.initialState, to.query?.term);
      next();
    },
    mounted() {
      const currentShopData = JSON.parse(sessionStorage.getItem('currentShopData'));

      this.$stats.send('page_opened', {
        page_type: 'search',
      });

      if(currentShopData?.origin_title) {
        this.setCurrentSearchLink(`/search/?term=${this.$route.query?.term}&provider_id=${this.$route.query?.providerId}`);
      };
    },
    methods: {
      ...mapActions({
        toggleLoading: 'searchPage/toggleLoading',
        setSearchDataAction: 'searchPage/setSearchData',
        changeLink: 'searchPage/changeLink',
        setCurrentSearchLink: 'searchInStore/setCurrentSearchLink',
        fetchStores: 'searchInStore/fetchAllStoresObj',
      }),
      getData(query, state = null, term) {
        this.initialState = state;
        this.$stats.send('search_request', {
          search_term: term, search_type: 'reqular',
        });
        amsClient.callAms(query, { cache: false })
          .then(data => {
            this.setSearchDataAction(data);
            this.checkInitState(data);
            this.toggleLoading(false);
          })
          .catch(err => {
            console.warn(err);
          });
      },
      checkInitState(data) {
        if (this.initialState) {
          const { catalog } = data.items?.filter(item => !!item?.catalog)[0];
          const item = catalog?.find(element => element.title === this.initialState);

          if (item.count) {
            this.changeLink({ item, isSubMenu: true });
          }

          this.initialState = null;
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './Search';
</style>
