<template>
  <div
    v-if="isRangeShow" 
    class="range-input"
  >
    <div class="range-input__slots">
      <div class="range-input__slots__item">
        <span class="current-value">
          {{ range[0] }}
        </span>

        <span>
          $
        </span>
      </div>

      <div class="range-input__slots__item">
        <span class="current-value">
          {{ range[1] }}
        </span>

        <span>
          $
        </span>
      </div>
    </div>
    <v-app class="range-input__slider-wrapper">
      <v-range-slider
        v-model="range"
        class="range-input__slider-wrapper--slider"
        :max="max"
        :min="min"
        :disabled="isItemsLoading"
        hide-details
        color="#B28446"
        track-color="#DBE0E9"
        @change="dynamicChangeHandler()"
      />
    </v-app>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex';
  import { debounce } from 'lodash';

  export default {
    name: 'PriceRangeInput',
    data () {
      return {
        range: [],
      }
    },
    computed: {
      ...mapGetters({
        carousels: 'searchPage/carousels',
        getProductPrices: 'searchPage/getProductPrices',
        getProductsArrayCopy: 'searchPage/getProductsArrayCopy',
        isItemsLoading: 'searchPage/loading',
      }),
      min() {
        if(this.getProductPrices.length >= 2) {
          return Math.min.apply(null, this.getProductPrices);
        };
        
        return 0;
      },
      max() {
        if(this.getProductPrices.length >= 2) {
          return Math.max.apply(null, this.getProductPrices);
        };

        if(this.getProductPrices.length === 1) {
          return this.getProductPrices[0];
        };

        return 400;
      },
      setRange() {
        return [this.min, this.max];
      },
      isRangeShow() {
        if(this.carousels?.length) {
          const products = this.carousels.find(item => item.title === 'Products');
          
          return products ? products.count > 0 : products;
        };
        
        return false;
      },
    },
    created() {
      this.$bus.$on('setPriceInputRange', () => {
        this.range = this.setRange;
      });

      this.dynamicChangeHandler = debounce(this.changeHandler, 1000);
    },
    beforeDestroy() {
      this.$bus.$off('setPriceInputRange');
    },
    methods: {
      ...mapActions({
        setProductsArrayCopy: 'searchPage/setProductsArrayCopy',
      }),
      ...mapMutations({
        setCarousels: 'searchPage/setCarousels',
      }),
      changeHandler() {
        const filteredCarousels = this.carousels.map(carousel => {
          if(carousel.title === 'Products') {
            let {items, ...rest} = carousel;

            items = this.getProductsArrayCopy.filter(product => product.price >= this.range[0] && product.price <= this.range[1]);

            rest.items = items;

            return rest;
          };

          return carousel;
        });

        this.setCarousels(filteredCarousels);
      },
    },
  }
</script>

<style lang="scss" scoped>
  .range-input {
    display: flex;
    flex-direction: column;
    margin-right: 30px;

    &__slots {
      display: flex;
      justify-content: space-between;

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 135px;
        height: 50px;
        border: 1px solid $main-input-border-color;

        &:last-child {
          margin-left: 10px;
        }

        span {
          font-size: 15px;
          font-weight: 400;
          margin: 0px 20px;
        }
      }
    }

    &__slider-wrapper {
      width: 100%;
      height: 80px;
      margin-top: 20px;
      overflow: hidden;
      background-color: $container-background-color;

      &--slider {
        margin-top: 15px;
        width: 85%;
        margin-left: 20px;
      }
    }
  }
</style>