<template>
  <div
    class="store-filter-wrapper"
  >
    <h5>Stores</h5>

    <input 
      class="store-filter-wrapper__text-input" 
      type="text"
      placeholder="Search"
      maxlength="100"
      :disabled="isItemsLoading"
      @input="dynamicFilterStores($event)"
    >
    
    <ul 
      class="store-filter-wrapper__list"
      :style="listOpenObject"
    >
      <li 
        v-for="(store, i) of getActualStoresArray"
        :key="i"
        class="store-filter-wrapper__list__item"
      >
        <label 
          class="custom-label"
        >
          <div class="custom-label__checkbox">
            <img 
              v-if="storeCheckArray.includes(store.origin_title)"
              :src="require('/src/assets/icons/check-mark.svg')" 
              alt="check"
            >
          </div>

          <input 
            v-model="storeCheckArray"
            type="checkbox"
            :value="store.origin_title"
            :disabled="isItemsLoading"
            @change="clickOnStore($event)"
          >

          {{ 
            store.origin_title.length >= maxStoreNameLength 
              ? 
                `${store.origin_title.slice(0, actualStoreLength)}...` 
              : 
                store.origin_title 
          }}
        </label>

        <img v-if="store.poster" :src="store.poster" alt="store_img">

        <div v-else class="image-substitute"/>
      </li>
    </ul>

    <div class="store-filter-wrapper__footer">
      <span v-if="getStoresArr">
        View all {{ getActualStoresArray.length }}
      </span>

      <button @click="openCloseList">
        <div class="arrow" :class="{toTop: isListOpen}"/>
      </button>
    </div>

    <div class="divider"/>
  </div>
</template>

<script>
  import { amsClient } from 'src/service/ams';
  import {mapGetters, mapActions} from 'vuex';
  import PriceRangeInput from '@/components/priceRangeInput/priceRangeInput';
  import { debounce } from 'lodash';

  export default {
    name: 'StoresFilterList',
    components: {
      PriceRangeInput,
    },
    data() {
      return {
        storeCheckArray: [],
        isListOpen: false,
        maxStoreNameLength: 26,
        actualStoreLength: 23,
      };
    },
    computed: {
      ...mapGetters({
        getCurrentSearchLink: 'searchInStore/getCurrentSearchLink',
        getActualStoresArray: 'searchInStore/getActualStoresArray',
        isItemsLoading: 'searchPage/loading',
        getStoresArr: 'searchInStore/getStoresArr',
      }),
      sortedStoresArr() {
        if(!this.getStoresArr?.length) {
          return [this.currentShopData];
        };

        const newStoresArr = [...this.getStoresArr].filter(s => s.origin_title !== this.currentShopData.origin_title);
        newStoresArr.unshift(this.currentShopData);

        return newStoresArr;
      },
      listItemHeight() {
        return document.getElementsByClassName('store-filter-wrapper__list__item')[0].offsetHeight;
      },
      computedListHeight() {
        if(this.sortedStoresArr) {
          return this.sortedStoresArr.length * this.listItemHeight;
        };

        return 0;
      },
      listOpenObject() {
        if (this.isListOpen){
          return {
            maxHeight: `${this.computedListHeight + 10}px`,
            transition: 'all',
            transitionDuration: '0.9s',
          };
        };

        return null;
      },
      currentShopData() {
        return JSON.parse(sessionStorage.getItem('currentShopData'));
      },
    },
    created() {
      this.storeCheckArray.push(this.currentShopData.origin_title);
      this.dynamicFilterStores = debounce(this.filterStores, 1000);

      this.setActualStoresArray(this.sortedStoresArr);
    },
    methods: {
      ...mapActions({
        toggleLoading: 'searchPage/toggleLoading',
        setSearchDataAction: 'searchPage/setSearchData',
        setCurrentSearchLink: 'searchInStore/setCurrentSearchLink',
        setActualStoresArray: 'searchInStore/setActualStoresArray',
        setProductsArrayCopy: 'searchPage/setProductsArrayCopy',
        setProductPrices: 'searchPage/setProductPrices',
      }),
      filterStores(e) {
        const searchStr = e.target.value.trim();

        if(!searchStr) {
          this.setActualStoresArray(this.sortedStoresArr);

          return;
        };

        const filteredArr = this.sortedStoresArr.filter(store => {
          return store.origin_title.toLowerCase().includes(searchStr.toLowerCase());
        });

        this.setActualStoresArray(filteredArr);
      },
      openCloseList() {
        if(this.isListOpen) {
          window.scrollTo(0, 0);
        };

        this.isListOpen = !this.isListOpen;
      },
      async clickOnStore(e) {
        let link = '';

        const shop = this.sortedStoresArr.find(s => s.origin_title === e.target.value);

        this.toggleLoading(true);
          
        if(e.target.checked) {
          link = this.getCurrentSearchLink + `,${shop.provider_id}`;
        }else {
          link = this.getCurrentSearchLink.replace(new RegExp(`,${shop.provider_id}|${shop.provider_id}`), '');
        };

        this.setCurrentSearchLink(link);

        if(!this.storeCheckArray.length) {
          await amsClient
            .callAms(`search?term=${this.$route.query.term}`)
            .then(data => {
              this.setSearchDataAction(data);
              this.toggleLoading(false);
            })
            .catch(e => {
              console.error('Fetching products failed.', e);
            });

          return;
        };

        await amsClient.callAms(link)
          .then(data => {
            const newProdsObj = data?.items.find(i => i.title === 'Products');

            this.setSearchDataAction(data);
            this.setProductsArrayCopy(newProdsObj.items);
            this.setProductPrices();
            this.toggleLoading(false);
          })
          .catch(e => {
            console.error('Fetching products of current page failed.', e);
          });
      },
    },
  }
</script>

<style lang="scss" scoped>
  ul.openList {
    max-height: unset;
    height: 100%;
  }
  .store-filter-wrapper {
    margin-bottom: 13px;
    
    h5 {
      font-size: 16px;
      font-weight: 600;
    }

    .divider {
      width: calc(100% - 30px);
      height: 1px;
      background-color: $main-input-border-color;
      margin-bottom: 30px;
    }

    &__text-input {
      border: 1px solid $main-input-border-color;
      width: calc(100% - 30px);
      font-size: 14px;
      padding: 9px 12px;
      margin: 12px 0px;
    }

    &__list {
      list-style-type: none;
      max-height: 295px;
      overflow-y: hidden;
      transition: all;
      transition-duration: 0.9s;
      overflow-x: hidden;

      &__item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;

        .custom-label {
          display: flex;
          margin: 5px 0px;
          cursor: pointer;

          &__checkbox {
            position: relative;
            width: 20px;
            height: 20px;
            border: 1px solid $main-input-border-color;
            margin-right: 10px;
          }

          img {
            position: absolute;
            top: 1px;
            left: 2px;
            width: 14px;
            height: 16px;
          }

          input {
            position: absolute;
            opacity: 0;
            z-index: -1;
          }
        }

        .image-substitute {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 20px;
          background-color: grey;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 20px;
          border-radius: 50%;
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      margin-bottom: 10px;

      button {
        width: 25px;
        height: 25px;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        margin-right: 10px;

        .arrow {
          width: 8px;
          height: 8px;
          border-left: 2px solid $main-black;
          border-bottom: 2px solid $main-black;
          transform: rotateZ(-45deg);
          transition: all;
          transition-duration: 1s;

          &.toTop {
            transform: rotateZ(495deg);
            transition: all;
            transition-duration: 1s;
          }
        }
      }
    }
  }
</style>